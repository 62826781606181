
/*
* A dropdown module to handle dropdown display and aria-attributes based on defined
* `data-` and `hidden` attributes.
*
* Usage instructions:
*
* 1. Include the module file on your page (preferably in the end)
* 2. Initialize the module with: dropdown.init();
* 3. Add an `id` to each `flix-dropdown__items` element you wish to show/hide
* 4. Connect the target to the dropdown items by passing the dropdown list id to the target
*    `data-dropdown` attribute
* 5. Optionally you can configure if the dropdown is to be shown on click or hover by
*    passing `data-event="click|hover" to the target
*/

/*global define*/
/*eslint no-undef: ["error", { "typeof": true }] */

(function (root, factory) {
  if ( typeof define === 'function' && define.amd ) {
    // AMD. Register as an anonymous module.
    define([], factory(root));
  } else if ( typeof exports === 'object' ) {
    module.exports = factory(root);
  } else {
    // Browser globals (root is window)
    root.dropdown = factory();
  }
})(typeof global !== 'undefined' ? global : this.window || this.global, function () {

  'use strict';

  var dropdown = {};

  // selector for the target elements that we should apply the module handlers
  var TARGET_SELECTOR = 'data-dropdown';
  // dropdown active modifier
  var DROPDOWN_CLASS = 'flix-dropdown';
  var ACTIVE_MODIFIER = 'flix-dropdown--active';
  // allowed events to listen to
  var ALLOWED_EVENTS = ['click', 'hover'];
  var DEFAULT_EVENT = 'click';
  // DELAY (in milliseconds) to trigger toggle when event is hover
  var DELAY = '300';


  /**
   * Returns the dropdown event if it's an allowed event, otherwise
   * returns the default event.
   * @param {Node} target
   * @returns "click" or "hover"
   */
   var getDropdownEvent = function(target) {
    var customEvent = target.getAttribute('data-event');

    if (customEvent && ALLOWED_EVENTS.indexOf(customEvent.toLowerCase()) >= 0) {
      return customEvent;
    }

    return DEFAULT_EVENT;
  };

  /**
   * Toggles dropdown based on the current state of the list's `hidden` attribute
   * @private
   */
  var toggleDropdown = function (target, dropdownElement) {
    if (dropdownElement.hasAttribute('hidden')) {
      showDropdown(target, dropdownElement);
    } else {
      hideDropdown(target, dropdownElement);
    }
  };

  /**
   * Shows dropdown and sets target expanded to true if "click" event
   * @private
   */
  var showDropdown = function (target, dropdownElement) {
    dropdownElement.removeAttribute('hidden');
    target.setAttribute('aria-expanded', 'true');
    if (target.parentElement.classList.contains(DROPDOWN_CLASS)) {
      target.parentElement.classList.add(ACTIVE_MODIFIER);
    }
  };

  /**
   * Hides dropdown and sets target expanded to false if "click" event
   * @private
   */
  var hideDropdown = function (target, dropdownElement) {
    dropdownElement.setAttribute('hidden', '');
    target.setAttribute('aria-expanded', 'false');
    target.parentElement.classList.remove(ACTIVE_MODIFIER);
  };

  /**
   * Event handlers for click
   * @private
   */
  var handleClick = function (target, dropdownElement) {
    target.addEventListener('click', function() {
      toggleDropdown(target, dropdownElement);
    });
  };

  /**
   * Event handlers for keyboard navigation
   * Fallback activation for non-button targets
   * @private
   */
  var handleKeydown = function (target, dropdownElement) {
    var menuItems = dropdownElement.querySelectorAll('[role="menuitem"]');
    var lastItemIndex = menuItems.length - 1;
    var firstMenuItem = menuItems.item(0);
    var lastMenuItem = menuItems.item(lastItemIndex);

    target.addEventListener('keydown', function(event) {
      // shows dropdown and sets proper focus with arrow keys
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        showDropdown(target, dropdownElement);

        if (event.key === 'ArrowDown') {
          firstMenuItem.focus();
        } else {
          lastMenuItem.focus();
        }
      }

      // sets focus to first item when activating with enter or space bar
      if (event.key === ' ' || event.key === 'Enter') {
        showDropdown(target, dropdownElement);
        firstMenuItem.focus();
      }
    });

    // cycles menu item focus with arrow keys
    menuItems.forEach(function (menuItem, index) {
      menuItem.addEventListener('keydown', function (event) {
        if (event.key === 'ArrowDown') {
          // if is last menuItem, then nextItem is firstItem
          var nextItemIndex = (index + 1) > lastItemIndex ? 0 : index + 1;
          var nextMenuItem = menuItems.item(nextItemIndex);
          nextMenuItem.focus();
        } else if (event.key === 'ArrowUp') {
          // if is first menuItem, then prevItem is lastItem
          var prevItemIndex = (index - 1) < 0 ? lastItemIndex : index - 1;
          var prevMenuItem = menuItems.item(prevItemIndex);
          prevMenuItem.focus();
        }
      });
    });

    // hides dropdown when shift+tab from first item
    firstMenuItem.addEventListener('keydown', function (event) {
      if (event.shiftKey && event.key === 'Tab') {
        hideDropdown(target, dropdownElement);
      }
    });

    // hides dropdown when tab from last item
    lastMenuItem.addEventListener('keydown', function (event) {
      if (!event.shiftKey && event.key === 'Tab') {
        hideDropdown(target, dropdownElement);
      }
    });

    // hides dropdown when pressing Escape
    [target, dropdownElement].forEach(function (element) {
      element.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
          hideDropdown(target, dropdownElement);
          target.focus();
        }
      });
    });
  };

  /**
   * Event handlers for when data-event is `hover`
   * @private
   */
  var handleHover = function (target, dropdownElement) {
    var currentTimeOut;

    // shows dropdown when mouse cursor hovers in
    target.addEventListener('mouseenter', function() {
      clearTimeout(currentTimeOut);
      currentTimeOut = setTimeout(showDropdown, DELAY, target, dropdownElement);
    });

    // hides dropdown when mouse cursor leaves the target and dropdown area
    // and is not hovering or focusing a Dropdown element
    [target, dropdownElement].forEach(function (element) {
      element.addEventListener('mouseleave', function(event) {
        if (!dropdownElement.contains(event.relatedTarget) && !dropdownElement.contains(document.activeElement)) {
          clearTimeout(currentTimeOut);
          currentTimeOut = setTimeout(hideDropdown, DELAY, target, dropdownElement);
        }
      });
    });
  };

  /**
   * Handles document clicks to close dropdown items when outside clicks happen
   * @private
   */
  var handleDocumentClick = function(event) {
    // only hides active dropdowns that have a target controlled by the plugin
    var activeDropdowns = document.querySelectorAll('['+ TARGET_SELECTOR +'][aria-expanded="true"]');

    activeDropdowns.forEach(function (activeDropdown) {
      var activeDropdownElement = document.querySelector('#' + activeDropdown.getAttribute(TARGET_SELECTOR));

      if (activeDropdown !== null && activeDropdownElement !== null && !activeDropdown.parentElement.contains(event.target)) {
        hideDropdown(activeDropdown, activeDropdownElement);
      }
    });
  };

  /**
   * Handles the event listeners based on the target's desired `data-event`
   * @private
   */
  var attachDropdownEventHandlers = function (target, dropdownElement) {
    handleClick(target, dropdownElement);
    handleKeydown(target, dropdownElement);

    if (getDropdownEvent(target) === 'hover') {
      handleHover(target, dropdownElement);
    }
  };

  /**
   * Sets basic accessibility attributes to connect the dropdown trigger with the dropdown menu
   * and enable assistive technologies to announce it as such
   * @private
   */
  var setupBaseA11yAttributes = function (target, dropdownElement) {
    const dropdownId = dropdownElement.getAttribute('id');
    const isDropdownHidden = dropdownElement.hasAttribute('hidden');
    const dropdownItems = dropdownElement.querySelectorAll('li');

    target.setAttribute('aria-controls', dropdownId);
    target.setAttribute('aria-expanded', !isDropdownHidden);
    target.setAttribute('aria-haspopup', 'menu');
    dropdownElement.setAttribute('role', 'menu');
    dropdownItems.forEach(function (item) {
      item.setAttribute('role', 'presentation');
      item.firstElementChild.setAttribute('role', 'menuitem');
    });
  };

  /**
   * Initialize the plugin
   */
  dropdown.init = function () {
    var dropdownTargets = document.querySelectorAll('['+ TARGET_SELECTOR +']');

    dropdownTargets.forEach(function (target) {
      var dropdownId = target.getAttribute(TARGET_SELECTOR);
      var dropdownElement = document.getElementById(dropdownId);

      if (dropdownElement === null) {
        /* eslint-disable no-console */
        console.warn('dropdown.js plugin error: Cannot find element with id ' + dropdownId + ' for target ' + target);
      } else {
        setupBaseA11yAttributes(target, dropdownElement);
        attachDropdownEventHandlers(target, dropdownElement);
      }
    });

    // clears previous document click listener to avoid multiple calls
    document.removeEventListener('click', handleDocumentClick);
    // adds new document click listener to close open dropdown when user clicks outside of them
    document.addEventListener('click', handleDocumentClick);
  };

  return dropdown;
});
